import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tab, TabPanel, Tabs, TabsList } from "@appsmith/ads";

import InputsForm from "../common/InputsForm";
import { Header, Body, Container } from "../common";
import {
  getIsModuleInstanceRunningStatus,
  getModuleInstanceById,
  getModuleInstancePublicAction,
} from "ee/selectors/moduleInstanceSelectors";
import { getModuleById } from "ee/selectors/modulesSelector";
import SettingsForm from "./SettingsForm";
import type { Action, PluginType } from "entities/Action";
import {
  runQueryModuleInstance,
  updateModuleInstanceOnPageLoadSettings,
  updateModuleInstanceSettings,
} from "ee/actions/moduleInstanceActions";
import Loader from "../../ModuleEditor/Loader";
import ResponseView from "./ResponseView";
import { hasExecuteModuleInstancePermission } from "ee/utils/permissionHelpers";
import { useModuleFallbackSettingsForm } from "../../ModuleEditor/ModulePluginActionEditor/hooks";
import { getPackageById } from "ee/selectors/packageSelectors";
import MissingModule from "../common/MissingModule";

export interface QueryModuleInstanceEditorProps {
  moduleInstanceId: string;
  defaultTab?: ModuleInstanceEditorTab;
}

const StyledInputsFormWrapper = styled.div`
  width: 270px;
`;

const StyledSettingsWrapper = styled.div`
  max-width: 600px;
`;

export enum ModuleInstanceEditorTab {
  SETTINGS = "SETTINGS",
  INPUTS = "INPUTS",
}

function QueryModuleInstanceEditor({
  defaultTab = ModuleInstanceEditorTab.INPUTS,
  moduleInstanceId,
}: QueryModuleInstanceEditorProps) {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const dispatch = useDispatch();
  const moduleInstance = useSelector((state) =>
    getModuleInstanceById(state, moduleInstanceId),
  );
  const module = useSelector((state) =>
    getModuleById(state, moduleInstance?.sourceModuleId || ""),
  );
  const pkg = useSelector((state) =>
    getPackageById(state, module?.packageId || ""),
  );
  const publicAction = useSelector((state) =>
    getModuleInstancePublicAction(state, moduleInstanceId),
  );
  const hasMissingModule = Boolean(moduleInstance?.invalids?.length);
  const fallbackSettings = useModuleFallbackSettingsForm({
    pluginId: publicAction?.pluginId || "",
    pluginType: (publicAction?.pluginType as PluginType) || "",
    interfaceType: "CREATOR",
  });

  const isExecutePermitted = hasExecuteModuleInstancePermission(
    moduleInstance?.userPermissions,
  );

  const { isRunning } = useSelector((state) =>
    getIsModuleInstanceRunningStatus(state, moduleInstanceId),
  );

  const onSettingsFormChange = useCallback(
    (values?: Action) => {
      if (!publicAction) return;

      if (publicAction?.executeOnLoad != values?.executeOnLoad) {
        dispatch(
          updateModuleInstanceOnPageLoadSettings({
            actionId: publicAction.id,
            value: values?.executeOnLoad,
          }),
        );
      } else {
        dispatch(updateModuleInstanceSettings(values));
      }
    },

    [publicAction?.executeOnLoad, publicAction?.id],
  );

  const onRunClick = useCallback(() => {
    dispatch(runQueryModuleInstance({ id: moduleInstance?.id || "" }));
  }, [dispatch, moduleInstance?.id]);

  if (!moduleInstance || (!hasMissingModule && (!module || !publicAction))) {
    return <Loader />;
  }

  const moduleSettings =
    module && Boolean(module?.settingsForm.length)
      ? module?.settingsForm
      : fallbackSettings;

  return (
    <Container>
      <Header
        isDisabled={hasMissingModule}
        moduleId={module?.originModuleId}
        moduleInstance={moduleInstance}
        packageId={pkg?.originPackageId}
      >
        {!hasMissingModule && (
          <Button
            className="t--run-query"
            data-guided-tour-id="run-module-instance"
            isDisabled={!isExecutePermitted}
            isLoading={isRunning}
            onClick={onRunClick}
            size="md"
          >
            Run
          </Button>
        )}
      </Header>
      <Body>
        {hasMissingModule && <MissingModule moduleInstance={moduleInstance} />}
        {!hasMissingModule && module && (
          <Tabs
            defaultValue={ModuleInstanceEditorTab.INPUTS}
            onValueChange={(tab) => {
              setSelectedTab(tab as ModuleInstanceEditorTab);
            }}
            value={selectedTab}
          >
            <TabsList>
              <Tab
                data-testid={
                  `t--module-instance-js-editor-` +
                  ModuleInstanceEditorTab.INPUTS
                }
                value={ModuleInstanceEditorTab.INPUTS}
              >
                Inputs
              </Tab>
              <Tab
                data-testid={
                  `t--module-instance-js-editor-` +
                  ModuleInstanceEditorTab.SETTINGS
                }
                value={ModuleInstanceEditorTab.SETTINGS}
              >
                Settings
              </Tab>
            </TabsList>
            <TabPanel value={ModuleInstanceEditorTab.INPUTS}>
              <StyledInputsFormWrapper>
                <InputsForm
                  defaultValues={{ inputs: moduleInstance.inputs }}
                  inputsForm={module.inputsForm}
                  moduleInstanceId={moduleInstanceId}
                  moduleInstanceName={moduleInstance.name}
                />
              </StyledInputsFormWrapper>
            </TabPanel>
            <TabPanel value={ModuleInstanceEditorTab.SETTINGS}>
              <StyledSettingsWrapper>
                <SettingsForm
                  initialValues={publicAction}
                  moduleInstanceId={moduleInstanceId}
                  onFormValuesChange={onSettingsFormChange}
                  settings={moduleSettings}
                />
              </StyledSettingsWrapper>
            </TabPanel>
          </Tabs>
        )}
      </Body>
      {!hasMissingModule && publicAction && (
        <ResponseView
          action={publicAction}
          isExecutePermitted={isExecutePermitted}
          moduleInstance={moduleInstance}
          onRunClick={onRunClick}
        />
      )}
    </Container>
  );
}

export default QueryModuleInstanceEditor;
