import React, { useCallback, useEffect, useMemo, useRef } from "react";
import type { AppState } from "ee/reducers";
import {
  getRunHistoryDetailsData,
  getRunHistoryDetailsLoadingState,
} from "ee/selectors/workflowRunHistorySelectors";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Text } from "@appsmith/ads";
import { fetchWorkflowRunHistoryDetails } from "ee/actions/workflowRunHistoryActions";
import { RunHistoryDetailsListItem } from "./RunHistoryDetailsListItem";
import styled from "styled-components";
import type { APP_MODE } from "entities/App";
import { WorkflowExecutionStatus } from "./helpers";
import {
  createMessage,
  WORKFLOW_RUN_HISTORY_LOADING_TEXT,
} from "ee/constants/messages";

interface RunHistoryDetailsProps {
  workflowId: string;
  selectedRunId: string;
  workflowMode: APP_MODE;
}

const ActivitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 4px;
  padding-bottom: 4px;
`;

const RunHistoryDetailsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ExecutionStatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`;

export function RunHistoryDetailsList({
  selectedRunId,
  workflowId,
  workflowMode,
}: RunHistoryDetailsProps) {
  const dispatch = useDispatch();
  const isLoading = useSelector(getRunHistoryDetailsLoadingState);
  const data = useSelector((state: AppState) =>
    getRunHistoryDetailsData(state, selectedRunId),
  );
  // Interval to poll for run details
  const interval = useRef(0);

  const dispatchCallback = useCallback(() => {
    dispatch(
      fetchWorkflowRunHistoryDetails(workflowId, selectedRunId, workflowMode),
    );
  }, [dispatch, workflowId, selectedRunId, workflowMode]);

  // Extract activities and workflow status from redux data
  const { activities, workflowStatus } = useMemo(() => {
    if (!data)
      return {
        workflowStatus:
          WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_UNSPECIFIED,
        activities: [],
      };

    return {
      workflowStatus:
        data.workflowStatus !== undefined
          ? data.workflowStatus
          : WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_FAILED,
      activities: data ? data.activities : [],
    };
  }, [data]);

  // Poll for run details every 2 seconds after last fetch
  useEffect(() => {
    if (selectedRunId === "default" || isLoading) return;

    if (
      workflowStatus ===
      WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_RUNNING
    ) {
      !!interval.current && clearInterval(interval.current);
      interval.current = setInterval(() => {
        dispatchCallback();
      }, 2000);

      return () => clearInterval(interval.current);
    } else if (
      workflowStatus ===
      WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_UNSPECIFIED
    ) {
      dispatchCallback();
    }
  }, [dispatchCallback, selectedRunId, workflowStatus, isLoading]);

  if (selectedRunId === "default") {
    return null;
  }

  return (
    <RunHistoryDetailsListContainer data-testid="t--run-history-details-list-container">
      <ActivitiesContainer data-testid="t--run-history-details-list">
        {activities.length > 0
          ? activities.map((activity) => (
              <RunHistoryDetailsListItem
                data={activity}
                key={activity.activityId}
              />
            ))
          : null}
      </ActivitiesContainer>
      {workflowStatus ===
        WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_RUNNING && (
        <ExecutionStatusContainer data-testid="t--run-history-details-exec-status">
          <Spinner className="mr-[8px]" size="md" />
          <Text>{createMessage(WORKFLOW_RUN_HISTORY_LOADING_TEXT)}</Text>
        </ExecutionStatusContainer>
      )}
    </RunHistoryDetailsListContainer>
  );
}
